import { createStore } from 'vuex'

export default createStore({
  state: {
    token: "",
    expiry: "",
    username: "",
    isAuthenticated: false
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      console.log(localStorage.getItem('token'))
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.expiry = localStorage.getItem('expiry')
        state.username = localStorage.getItem('username')
        state.isAuthenticated = true
      }
      else {
        localStorage.removeItem('token')
        localStorage.removeItem('expiry')
        localStorage.removeItem('username')
        state.isAuthenticated = false
      }
    },
    setToken(state, data) {
      state.token = data.token
      state.expiry = data.expiry
      state.username = data.user.username.split("@")[0]
      localStorage.setItem('token', data.token)
      localStorage.setItem('expiry', data.expiry)
      localStorage.setItem('username', data.user.username)
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ""
      state.expiry = ""
      state.username = ""
      localStorage.removeItem('token')
      localStorage.removeItem('expiry')
      localStorage.removeItem('username')
      state.isAuthenticated = false
    }
  },
  actions: {
  },
  modules: {
  }
})
