<template>
  <q-page class="flex flex-center">
    <q-card class="q-pa-md shadow-2 my_card" bordered>
      <q-card-section class="text-center">
        <div class="text-grey-9 text-h5 text-weight-bold">Login</div>
        <div class="text-grey-8">Welcome to NextGenFarm</div>
      </q-card-section>
      <q-card-section>
        <q-input dense outlined v-model="username" label="Email"
          :rules="[(val) => (val && val.length > 0) || 'This field is required']">
        </q-input>
        <q-input dense outlined class="q-mt-md" v-model="password" type="password" label="Password"
          :rules="[(val) => (val && val.length > 0) || 'This field is required']">
        </q-input>
      </q-card-section>
      <q-card-section>
        <q-btn style="border-radius: 8px;" color="dark" rounded size="md" label="Login" no-caps class="full-width"
          :disabled="!username || !password" @click="try_login"></q-btn>
      </q-card-section>
      <q-card-section class="text-center text-red q-pt-none" v-if="error">
        {{ error }}
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginLayout',

  data() {
    return {
      username: "",
      password: "",
      error: ""
    }
  },
  methods: {
    try_login() {
      const url = 'api/login/'
      axios.post(url, { username: this.username, password: this.password })
        .then(response => {
          console.log(response.data)
          this.$store.commit('setToken', response.data)
          this.username = ""
          this.password = ""
          this.error = ""
          this.$router.push('home/')
        })
        .catch(error => {
          if (error.response.data.non_field_errors) {
            this.username = ""
            this.password = ""
            this.error = error.response.data.non_field_errors[0]
          }
          else {
            this.username = ""
            this.password = ""
            this.error = ""
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.my_card {
  width: 25rem;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}
</style>