<template>
  <NavbarComp />
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue'

export default {
  name: 'TodoView',
  components: {
    NavbarComp
  }
}
</script>

<style lang="scss" scoped></style>