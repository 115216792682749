<template>
  <NavbarComp />
  <div class="q-pa-sm row">
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Air Temperature</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; T : {{ air_temperature }} C</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="airTempChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Air Humidity</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; &emsp; RH : {{ air_humidity }} %</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="airHumChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Air Pressure</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; P : {{ air_pressure }} hPa</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="airPrChartData"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
  <div class="q-pa-sm row">
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Light Infrared</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; I : {{ infrared }} Lux</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="infraredChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Light Visible</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; V : {{ visible }} Lux</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="visibleChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Light Broadband</div>
          <div class="text text-center">{{ air_date }} &ensp; {{ air_time }} &emsp; B : {{ broadband }} Lux</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="broadbandChartData"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
  <div class="q-pa-sm row">
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Soil</div>
          <div class="text text-center">{{ soil_date }} &ensp; {{ soil_time }} &emsp; Moisture : {{ soil_moisture }} %</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="soilMoistureChartOptions"
            :data="soilMoistureChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Max Air Temperature</div>
          <div class="text text-center">{{ air_date }} &ensp; Max T : {{ max_air_temp_today }} C</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="maxAirTempChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Max Air Humidity</div>
          <div class="text text-center">{{ air_date }} &ensp; Max RH : {{ max_air_hum_today }} %</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="maxAirHumChartData"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
  <div class="q-pa-sm row">
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Min Air Pressure</div>
          <div class="text text-center">{{ air_date }} &ensp; Min Air Pressure : {{ min_air_pr_today }} hPa</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="minAirPrChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Min Air Temperature</div>
          <div class="text text-center">{{ air_date }} &ensp; Min T : {{ min_air_temp_today }} C</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="minAirTempChartData"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Min Air Humidity</div>
          <div class="text text-center">{{ air_date }} &ensp; Min RH : {{ min_air_hum_today }} %</div>
        </q-card-section>
        <q-card-section>
          <Line
            :options="chartOptions"
            :data="minAirHumChartData"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>

  <div class="q-pa-sm row">
    <div class="col">
      <q-card class="shadow-4 fit q-pa-sm">
        <q-card-section>
          <div class="text-subtitle1">Camera 1 <span class="float-right" >{{ cam_1_img_timestamp }}</span></div>
        </q-card-section>
        <q-img :src="cam_1_img" alt="Cam 1 Image"/>
      </q-card>
    </div>
  </div>
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue'
import axios from 'axios'
import moment from 'moment'

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

export default {
  name: 'MonitorView',

  data() {
    return {
      air_date: "YYYY-MM-DD",
      air_time: "HH:MM:SS",
      air_temperature: "NA",
      air_humidity: "NA",
      air_pressure: "NA",

      max_air_temp_today: "NA",
      max_air_hum_today: "NA",
      min_air_pr_today: "NA",
      min_air_temp_today: "NA",
      min_air_hum_today: "NA",

      soil_date: "YYYY-MM-DD",
      soil_time: "HH:MM:SS",
      soil_moisture: "NA",

      broadband: "NA",
      infrared: "NA",
      visible: "NA",

      timer: null,
      cam_1_img: null,
      cam_1_img_timestamp : "YYYY-MM-DD HH:MM:SS",
      //waterVapor: "https://mausam.imd.gov.in/Satellite/3Dasiasec_ir1.jpg",

      chartOptions: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },

      soilMoistureChartOptions: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            max: 120,
            ticks: {
                stepSize: 20,
                maxTicksLimit: 7
            }
          }
        }
      },

      airTempChartData: {
        labels: [],
        datasets: []
      },

      airHumChartData: {
        labels: [],
        datasets: []
      },

      airPrChartData: {
        labels: [],
        datasets: []
      },

      infraredChartData: {
        labels: [],
        datasets: []
      },

      visibleChartData: {
        labels: [],
        datasets: []
      },

      broadbandChartData: {
        labels: [],
        datasets: []
      },

      soilMoistureChartData: {
        labels: [],
        datasets: []
      },

      maxAirTempChartData: {
        labels: [],
        datasets: []
      },

      maxAirHumChartData: {
        labels: [],
        datasets: []
      },

      minAirPrChartData: {
        labels: [],
        datasets: []
      },

      minAirTempChartData: {
        labels: [],
        datasets: []
      },

      minAirHumChartData: {
        labels: [],
        datasets: []
      }
    }
  },

  components: {
    NavbarComp,
    Line
  },

  computed: {

  },

  mounted: function () {
    this.get_current_sensor_data()
    this.get_latest_cam_1_img()
    this.get_water_vapor()
    this.get_air_sensor_data()
    this.get_current_soil_sensor_data()
    this.get_soil_sensor_data()
    this.get_air_sensor_minmax_data()

    this.timer = setInterval(() => {
      this.get_current_sensor_data()
      this.get_latest_cam_1_img()
      this.get_water_vapor()
      this.get_air_sensor_data()
      this.get_current_soil_sensor_data()
      this.get_soil_sensor_data()
      this.get_air_sensor_minmax_data()
    }, 60000)
  },

  beforeUnmount() {
    clearInterval(this.timer)
  },

  methods: {
    get_soil_sensor_data() {
      let today = moment().format('YYYY-MM-DD')
      let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
      const url = 'api/sensor/soil/'+ today + '/' + tomorrow + '/1/'
      const token = this.$store.state.token

      let _labels = []
      let _moisture = []

      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            _labels.push(response.data.data[i]["timestamp"].split("T")[1].split("Z")[0])
            _moisture.push(response.data.data[i]["moisture"])
          }

          this.soilMoistureChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Moisture",
                data: _moisture,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }
        }).catch(error => {
          if (error.response.data.non_field_errors) {
            console.log(error.response.data.non_field_errors[0])
          }
        })
    },

    get_air_sensor_minmax_data() {
      let start = moment().subtract(30, 'days').format('YYYY-MM-DD')
      const url = 'api/sensor/air/minmax/' + start + '/1/'
      console.log(url)
      const token = this.$store.state.token

      let _labels = []
      let _max_temp = []
      let _max_hum = []
      let _min_pr = []
      let _min_temp = []
      let _min_hum = []
      
      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
        //console.log(response.data.data)

        const index = response.data.data.length - 1
        this.max_air_temp_today = response.data.data[index]["max_temp"]
        this.max_air_hum_today = response.data.data[index]["max_hum"]
        this.min_air_pr_today = response.data.data[index]["min_pr"]
        this.min_air_temp_today = response.data.data[index]["min_temp"]
        this.min_air_hum_today = response.data.data[index]["min_hum"]

        for (let i = 0; i < response.data.data.length; i++) {
          let max_temp = response.data.data[i]["max_temp"]
          let max_hum = response.data.data[i]["max_hum"]
          let min_pr = response.data.data[i]["min_pr"]
          let min_temp = response.data.data[i]["min_temp"]
          let min_hum = response.data.data[i]["min_hum"]

          if(max_temp > 0 && max_hum > 0 && min_pr > 0 && min_temp > 0 && min_hum > 0) {
            _labels.push(response.data.data[i]["date"].split("T")[0])
            _max_temp.push(max_temp)
            _max_hum.push(max_hum)
            _min_pr.push(min_pr)
            _min_temp.push(min_temp)
            _min_hum.push(min_hum)
          }
        }

        this.maxAirTempChartData = {
          labels: _labels,
          datasets: [
            {
              label: "Max Air Temperature",
              data: _max_temp,
              pointStyle: false,
              borderWidth: 2,
              tension: 0,
              borderColor: 'rgb(0, 0, 0)',
            }
          ]
        }

        this.maxAirHumChartData = {
          labels: _labels,
          datasets: [
            {
              label: "Max Air Humidity",
              data: _max_hum,
              pointStyle: false,
              borderWidth: 2,
              tension: 0,
              borderColor: 'rgb(0, 0, 0)',
            }
          ]
        }

        this.minAirPrChartData = {
          labels: _labels,
          datasets: [
            {
              label: "Min Air Pressure",
              data: _min_pr,
              pointStyle: false,
              borderWidth: 2,
              tension: 0,
              borderColor: 'rgb(0, 0, 0)',
            }
          ]
        }
        
        this.minAirTempChartData = {
          labels: _labels,
          datasets: [
            {
              label: "Min Air Temperature",
              data: _min_temp,
              pointStyle: false,
              borderWidth: 2,
              tension: 0,
              borderColor: 'rgb(0, 0, 0)',
            }
          ]
        }

        this.minAirHumChartData = {
          labels: _labels,
          datasets: [
            {
              label: "Min Air Humidity",
              data: _min_hum,
              pointStyle: false,
              borderWidth: 2,
              tension: 0,
              borderColor: 'rgb(0, 0, 0)',
            }
          ]
        }
      }).catch(error => {
        if (error.response.data.non_field_errors) {
          console.log(error.response.data.non_field_errors[0])
        }

        this.max_air_temp_today = "NA"
        this.max_air_hum_today = "NA"
        this.min_air_pressure = "NA"
        this.min_air_temp_today = "NA"
        this.min_air_hum_today = "NA"
      })
    },

    get_air_sensor_data() {
      let today = moment().format('YYYY-MM-DD')
      let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
      const url = 'api/sensor/'+ today + '/' + tomorrow + '/1/'
      const token = this.$store.state.token

      let _labels = []
      let _temperature = []
      let _pressure = []
      let _humidity = []
      let _infrared = []
      let _visible = []
      let _broadband = []

      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            let temperature = response.data.data[i]["temperature"]
            let pressure = response.data.data[i]["pressure"]
            let humidity = response.data.data[i]["humidity"]
            let infrared = response.data.data[i]["infrared"]
            let visible = response.data.data[i]["visible"]
            let broadband = response.data.data[i]["broadband"]

            if (temperature > 0 && pressure > 0 && humidity > 0 && infrared > 0 && visible > 0 && broadband > 0) {
              _labels.push(response.data.data[i]["timestamp"].split("T")[1].split("Z")[0])
              _temperature.push(temperature)
              _pressure.push(pressure)
              _humidity.push(humidity)
              _infrared.push(infrared)
              _visible.push(visible)
              _broadband.push(broadband)
            }
          } 

          this.airTempChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Temperature",
                data: _temperature,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }

          this.airHumChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Humidity",
                data: _humidity,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }

          this.airPrChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Pressure",
                data: _pressure,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }

          this.infraredChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Infrared",
                data: _infrared,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }

          this.visibleChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Visible",
                data: _visible,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }

          this.broadbandChartData = {
            labels: _labels,
            datasets: [
              {
                label: "Broadband",
                data: _broadband,
                pointStyle: false,
                borderWidth: 2,
                tension: 0,
                borderColor: 'rgb(0, 0, 0)',
              }
            ]
          }
        }).catch(error => {
          if (error.response.data.non_field_errors) {
            console.log(error.response.data.non_field_errors[0])
          }
        })
    },

    get_water_vapor() {
      this.waterVapor = "https://mausam.imd.gov.in/Satellite/3Dasiasec_ir1.jpg"
    },

    get_latest_cam_1_img() {
      const url = 'api/camera/latest/1/'
      const token = this.$store.state.token
      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
          let timestamp = response.data.data.uploaded_on.split("T")
          this.cam_1_img_timestamp = timestamp[0] + " " + timestamp[1].split(".")[0]
          //this.cam_1_img = "http://127.0.0.1:8000/media/images/01_ngf_2024-04-17-08-15-21.jpg"
          this.cam_1_img = "https://nextgenfarm.in" + response.data.data.file
        }).catch(error => {
          if (error.response.data.non_field_errors) {
            console.log(error.response.data.non_field_errors[0])
          }
          this.cam_1_img = null
          this.cam_1_img_timestamp = "YYYY-MM-DD HH:MM:SS"
        })
    },

    get_current_soil_sensor_data() {
      const url = 'api/sensor/soil/latest/1/'
      const token = this.$store.state.token

      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
          console.log(response.data.data.timestamp)
          if (response.data.data.timestamp != null) {
            this.soil_date = response.data.data.timestamp.split("T")[0]
            this.soil_time = response.data.data.timestamp.split("T")[1].slice(0, 8)
            this.soil_moisture = response.data.data.moisture
          } else {
            this.soil_date = "YYYY-MM-DD"
            this.soil_time = "HH:MM:SS"
            this.soil_moisture = "NA"
          }
        }).catch(error => {
          if (error.response.data.non_field_errors) {
            console.log(error.response.data.non_field_errors[0])
          }
          else {
            this.soil_date = "YYYY-MM-DD"
            this.soil_time = "HH:MM:SS"
            this.soil_moisture = "NA"
          }
        })
    },
    
    get_current_sensor_data() {
      const url = 'api/sensor/latest/1/'
      const token = this.$store.state.token

      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
          console.log(response.data.data.timestamp)
          if (response.data.data.timestamp != null) {
            this.air_date = response.data.data.timestamp.split("T")[0]
            this.air_time = response.data.data.timestamp.split("T")[1].slice(0, 8)
            this.air_temperature = response.data.data.temperature
            this.air_pressure = response.data.data.pressure
            this.air_humidity = response.data.data.humidity
            this.broadband = response.data.data.broadband
            this.infrared = response.data.data.infrared
            this.visible = response.data.data.visible  
          } else {
            this.air_date = "YYYY-MM-DD"
            this.air_time = "HH:MM:SS"
            this.air_temperature = "NA"
            this.air_pressure = "NA"
            this.air_humidity = "NA"
            this.broadband = "NA"
            this.infrared = "NA"
            this.visible = "NA"
          }
        }).catch(error => {
          if (error.response.data.non_field_errors) {
            console.log(error.response.data.non_field_errors[0])
          }
          else {
            this.air_date = "YYYY-MM-DD"
            this.air_time = "HH:MM:SS"
            this.air_temperature = "NA"
            this.air_pressure = "NA"
            this.air_humidity = "NA"
            this.broadband = "NA"
            this.infrared = "NA"
            this.visible = "NA"
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
