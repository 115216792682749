<template>
  <NavbarComp />
  <div class="q-pa-sm row">
    <div class="col col-xs-12 col-sm-6 col-md-3 col-lg-3 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Fogger</div>
        </q-card-section>
        <q-card-section>
          <q-markup-table>
            <tbody>
              <tr>
                <td class="text-left">Auto Mode</td>
                <td class="text-right">
                  <q-toggle
                    v-model="fogger_auto_mode"
                    color="green"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Active Environment</td>
                <td class="text-right">
                  <q-toggle
                    v-model="fogger_active_environment"
                    color="green"
                    :disable="!fogger_auto_mode"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Temperature <br /> Threshold</td>
                <td class="text-right">
                  <q-select
                    v-model="fogger_temperature_threshold"
                    :options="fogger_temperature_threshold_options"
                    label="Deg C"
                    :disable="!fogger_auto_mode || !fogger_active_environment"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Humidity <br /> Threshold</td>
                <td class="text-right">
                  <q-select
                    v-model="fogger_humidity_threshold"
                    :options="fogger_humidity_threshold_options"
                    label="% RH"
                    :disable="!fogger_auto_mode || !fogger_active_environment"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Start Time</td>
                <td class="text-right">
                  <q-input filled v-model="fogger_start_time" mask="time" :rules="['time']" :disable="!fogger_auto_mode">
                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-time
                            v-model="fogger_start_time"
                            color="green"
                            format24h
                            @update:model-value="update_fogger_controls()"
                          >
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="green" flat />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </td>
              </tr>
              <tr>
                <td class="text-left">Stop Time</td>
                <td class="text-right">
                  <q-input filled v-model="fogger_stop_time" mask="time" :rules="['time']" :disable="!fogger_auto_mode">
                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-time
                            v-model="fogger_stop_time"
                            color="red"
                            format24h
                            @update:model-value="update_fogger_controls()"
                          >
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="red" flat />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </td>
              </tr>
              <tr>
                <td class="text-left">ON Duration</td>
                <td class="text-right">
                  <q-select
                    v-model="fogger_on_duration"
                    :options="fogger_on_duration_options"
                    label="Minutes"
                    :disable="!fogger_auto_mode"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">OFF Duration</td>
                <td class="text-right">
                  <q-select
                    v-model="fogger_off_duration"
                    :options="fogger_off_duration_options"
                    label="Minutes"
                    :disable="!fogger_auto_mode"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Manual ON</td>
                <td class="text-right">
                  <q-toggle
                    v-model="fogger_manual_on"
                    color="green"
                    :disable="fogger_auto_mode"
                    @update:model-value="update_fogger_controls()"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>
      </q-card>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-3 col-lg-3 q-pa-sm">
      <q-card class="shadow-4 fit">
        <q-card-section>
          <div class="text-h6 text-center">Irrigation</div>
        </q-card-section>
        <q-card-section>
          <q-markup-table>
            <tbody>
              <tr>
                <td class="text-left">Auto Mode</td>
                <td class="text-right">
                  <q-toggle
                    v-model="irrigation_auto_mode"
                    color="green"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Active Soil Moisture</td>
                <td class="text-right">
                  <q-toggle
                    v-model="irrigation_active_soil_moisture"
                    color="green"
                    :disable="!irrigation_auto_mode"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Soil Moisture <br /> ON Threshold</td>
                <td class="text-right">
                  <q-select
                    v-model="irrigation_moisture_on_threshold"
                    :options="irrigation_moisture_on_threshold_options"
                    label="% Soil Mosture"
                    :disable="!irrigation_auto_mode || !irrigation_active_soil_moisture"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Soil Moisture <br /> OFF Threshold</td>
                <td class="text-right">
                  <q-select
                    v-model="irrigation_moisture_off_threshold"
                    :options="irrigation_moisture_off_threshold_options"
                    label="% Soil Mosture"
                    :disable="!irrigation_auto_mode || !irrigation_active_soil_moisture"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Days</td>
                <td class="text-right">
                  <q-select
                    v-model="irrigation_days"
                    :options="irrigation_days_options"
                    label="Irrigation days"
                    :disable="!irrigation_auto_mode"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Start Time</td>
                <td class="text-right">
                  <q-input filled v-model="irrigation_start_time" mask="time" :rules="['time']" :disable="!irrigation_auto_mode">
                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-time
                            v-model="irrigation_start_time"
                            color="green"
                            format24h
                            @update:model-value="update_irrigation_controls()"
                          >
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="green" flat />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </td>
              </tr>
              <tr>
                <td class="text-left">Stop Time</td>
                <td class="text-right">
                  <q-input filled v-model="irrigation_stop_time" mask="time" :rules="['time']" :disable="!irrigation_auto_mode">
                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-time
                            v-model="irrigation_stop_time"
                            color="green"
                            format24h
                            @update:model-value="update_irrigation_controls()"
                          >
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="green" flat />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </td>
              </tr>
              <tr>
                <td class="text-left">ON Duration</td>
                <td class="text-right">
                  <q-select
                    v-model="irrigation_on_duration"
                    :options="irrigation_on_duration_options"
                    label="Minutes"
                    :disable="!irrigation_auto_mode"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-left">Manual ON</td>
                <td class="text-right">
                  <q-toggle
                    v-model="irrigation_manual_on"
                    color="green"
                    :disable="irrigation_auto_mode"
                    @update:model-value="update_irrigation_controls()"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue'
import axios from 'axios'

export default {
  name: 'ControlsView',
  components: {
    NavbarComp
  },
  data() {
    return {
      fogger_auto_mode : false,
      fogger_active_environment : false,
      fogger_temperature_threshold : "NA",
      fogger_temperature_threshold_options : [30, 35, 40],
      fogger_humidity_threshold : "NA",
      fogger_humidity_threshold_options : [50, 60, 70, 80, 90],
      fogger_manual_on : false,
      fogger_start_time : "NA",
      fogger_stop_time : "NA",
      fogger_on_duration : "NA",
      fogger_on_duration_options : [1, 2, 3, 4, 5],
      fogger_off_duration : "NA",
      fogger_off_duration_options : [5, 10, 15, 20, 25, 30],

      irrigation_auto_mode : false,
      irrigation_active_soil_moisture : false,
      irrigation_moisture_on_threshold : "NA",
      irrigation_moisture_on_threshold_options : [10, 20, 30, 40, 50, 60, 70, 80, 90],
      irrigation_moisture_off_threshold : "NA",
      irrigation_moisture_off_threshold_options : [10, 20, 30, 40, 50, 60, 70, 80, 90],
      irrigation_manual_on : false,
      irrigation_days : "NA",
      irrigation_days_options : ["All", "Sun, Tue, Thu", "Sun, Wed"],
      irrigation_start_time : "NA",
      irrigation_stop_time : "NA",
      irrigation_on_duration : "NA",
      irrigation_on_duration_options : [15, 30, 45, 60, 75, 90, 105, 120]
    }
  },
  methods: {
    get_fogger_default_values() {
      const url = 'api/controls/fogger/latest/'
      const token = this.$store.state.token
      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.data.timestamp != null) {
          this.fogger_auto_mode = response.data.data.fogger_auto_mode
          this.fogger_active_environment = response.data.data.fogger_active_environment
          this.fogger_temperature_threshold = response.data.data.fogger_temperature_threshold
          this.fogger_humidity_threshold = response.data.data.fogger_humidity_threshold
          this.fogger_manual_on = response.data.data.fogger_manual_on
          this.fogger_start_time = response.data.data.fogger_start_time
          this.fogger_stop_time = response.data.data.fogger_stop_time
          this.fogger_on_duration = response.data.data.fogger_on_duration
          this.fogger_off_duration = response.data.data.fogger_off_duration
        }
      }).catch(error => {
        console.log(error.response.data.non_field_errors[0])
      })
    },

    get_irrigation_default_values() {
      const url = 'api/controls/irrigation/latest/'
      const token = this.$store.state.token
      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.data.timestamp != null) {
          this.irrigation_auto_mode = response.data.data.irrigation_auto_mode
          this.irrigation_active_soil_moisture = response.data.data.irrigation_active_soil_moisture
          this.irrigation_moisture_on_threshold = response.data.data.irrigation_moisture_on_threshold
          this.irrigation_moisture_off_threshold = response.data.data.irrigation_moisture_off_threshold
          this.irrigation_manual_on = response.data.data.irrigation_manual_on
          this.irrigation_days = response.data.data.irrigation_days
          this.irrigation_start_time = response.data.data.irrigation_start_time
          this.irrigation_stop_time = response.data.data.irrigation_stop_time
          this.irrigation_on_duration = response.data.data.irrigation_on_duration
        }
      }).catch(error => {
        console.log(error.response.data.non_field_errors[0])
      })
    },

    update_fogger_controls(){
      const url = 'api/controls/fogger/'
      const token = this.$store.state.token

      if(this.fogger_auto_mode) {
        this.fogger_manual_on = false
      }

      const obj = {
        "fogger_auto_mode": this.fogger_auto_mode,
        "fogger_active_environment": this.fogger_active_environment,
        "fogger_temperature_threshold": this.fogger_temperature_threshold,
        "fogger_humidity_threshold": this.fogger_humidity_threshold,
        "fogger_manual_on": this.fogger_manual_on,
        "fogger_start_time": this.fogger_start_time,
        "fogger_stop_time": this.fogger_stop_time,
        "fogger_on_duration": this.fogger_on_duration,
        "fogger_off_duration": this.fogger_off_duration
      }

      axios({
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        },
        data: obj
      }).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
      })
    },

    update_irrigation_controls() {
      const url = 'api/controls/irrigation/'
      const token = this.$store.state.token

      if(this.irrigation_auto_mode) {
        this.irrigation_manual_on = false
      }

      const obj = {
        "irrigation_auto_mode": this.irrigation_auto_mode,
        "irrigation_active_soil_moisture": this.irrigation_active_soil_moisture,
        "irrigation_moisture_on_threshold": this.irrigation_moisture_on_threshold,
        "irrigation_moisture_off_threshold": this.irrigation_moisture_off_threshold,
        "irrigation_manual_on": this.irrigation_manual_on,
        "irrigation_days": this.irrigation_days,
        "irrigation_start_time": this.irrigation_start_time,
        "irrigation_stop_time": this.irrigation_stop_time,
        "irrigation_on_duration": this.irrigation_on_duration
      }

      axios({
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        },
        data: obj
      }).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
      })
    }
  },

  mounted: function () {
    this.get_fogger_default_values()
    this.get_irrigation_default_values()
  }
}
</script>

<style lang="scss" scoped></style>