<template>
  <q-header elevated class="glossy">
    <q-toolbar>
      <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />

      <q-toolbar-title>
        NextGenFarm
      </q-toolbar-title>

      <q-btn flat dense round @click="logout" aria-label="Logout" icon="logout" />
    </q-toolbar>
  </q-header>

  <q-drawer v-model="leftDrawerOpen" bordered class="bg-grey-2">
    <q-list>
      <q-item-label header>Welcome {{ user }}</q-item-label>
      <q-separator />
      
      <q-item clickable tag="a" to="/home">
        <q-item-section avatar>
          <q-icon name="dashboard" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Dashboard</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable tag="a" to="/monitor">
        <q-item-section avatar>
          <q-icon name="camera" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Monitor</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable tag="a" to="/controls">
        <q-item-section avatar>
          <q-icon name="repeat" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Controls</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable tag="a" to="/expenses">
        <q-item-section avatar>
          <q-icon name="currency_rupee" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Expenses</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable tag="a" to="/todo">
        <q-item-section avatar>
          <q-icon name="list" />
        </q-item-section>
        <q-item-section>
          <q-item-label>To Do List</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable tag="a" to="/weather">
        <q-item-section avatar>
          <q-icon name="cloud" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Weather</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
</template>

<script>
import axios from 'axios'

export default {
  name: 'NavbarLayout',
  data() {
    return {
      user: this.$store.state.username,
      leftDrawerOpen: false
    }
  },
  methods: {
    logout() {
      const url = 'api/logout/'
      const token = this.$store.state.token
      axios({
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token  ' + token
        }
      })
      this.$store.commit('removeToken')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped></style>