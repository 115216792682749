<template>
  <NavbarComp />
  <div class="q-pa-lg col">
    <q-card class="text-center shadow-8 fit q-pa-lg q-mb-lg">
      <q-item>
        <q-item-section>
          <q-item-label class="text-h3">
            Water Vapor
          </q-item-label>
        </q-item-section>
      </q-item>

      <img v-bind:src="waterVapor" />
    </q-card>

    <q-card class="text-center shadow-8 fit q-pa-lg q-mb-lg">
      <q-item>
        <q-item-section>
          <q-item-label class="text-h3">
            Temperature
          </q-item-label>
        </q-item-section>
      </q-item>

      <img v-bind:src="actualTemp" />
    </q-card>

    <q-card class="text-center shadow-8 fit q-pa-lg q-mb-lg">
      <q-item>
        <q-item-section>
          <q-item-label class="text-h3">
            Rainfall
          </q-item-label>
        </q-item-section>
      </q-item>

      <img v-bind:src="actualRain" />
    </q-card>
  </div>
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue'

export default {
  name: 'WeatherView',
  components: {
    NavbarComp
  },
  data() {
    return {
      waterVapor: "https://mausam.imd.gov.in/Satellite/3Dasiasec_ir1.jpg",
      actualTemp: "https://mausam.imd.gov.in/ClimateInformation/imdweb/DAY_WEEK/t-0.gif",
      actualRain: "https://mausam.imd.gov.in/ClimateInformation/imdweb/DAY_WEEK/dtoday.gif",
    }
  }
}
</script>

<style lang="scss"></style>
